@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;700&family=Mrs+Saint+Delafield&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Redressed&family=Roboto:wght@100;300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply bg-lightClr h-full w-full;
    font-family: 'Poppins', 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  h1 {
    @apply text-6xl font-extrabold
  }

  h2 {
    @apply text-4xl lg:text-6xl font-extrabold
  }

  h3 {
    @apply text-3xl lg:text-5xl font-extrabold
  }

  h4 {
    @apply text-2xl lg:text-3xl font-extrabold
  }
}

@layer components {
  .btn-primary {
    @apply w-40 h-16 flex justify-center items-center text-2xl font-bold cursor-pointer py-2 px-4 rounded-lg transition hover:saturate-200 hover:-translate-y-1 hover:transition duration-300
  }
  .btn-disabled {
    @apply bg-slate-600 text-white w-40 h-16 flex justify-center items-center text-2xl font-bold py-2 px-4 rounded-lg cursor-not-allowed
  }
}

.icon-animate {
  @apply text-mainClr bg-white duration-700 shadow-icon
}

.layout-margin {
  @apply max-w-7xl mx-auto px-4 sm:px-6 md:px-8
}

.layout-margin-tight {
  @apply max-w-5xl mx-auto px-4 sm:px-6 md:px-8
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
