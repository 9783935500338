#root {

}

.app {
    display: flex;
    align-items: center;

    justify-content: center;
}

.innerDiv {
    display: flex;
    justify-content: center;

    align-items: center;

}

/*Cover Page*/
.coverFrom {
    text-align: center;
    font-size: 1em;
    font-weight: 500;
}

.coverFromValue {
    text-align: center;
    font-size: 1.8em;
    margin-top: 1vh;
    font-weight: 700;
}

.coverTap {
    padding-left: 1.8em;
    padding-right: 1.8em;
    padding-top: 1.2em;
    padding-bottom: 1.2em;
    background-color: #ffaa00;
    margin-top: 15vh;
    border-radius: 15px;
    color: white;
    font-size: 0.9em;
    font-weight: 600;
}

.page {
    background-color: rgb(255, 243, 210);
    box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.30);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.contentPage {
    background-color: rgb(255, 243, 210);
    box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.30);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.cardNotFoundPage {
    background-color: rgb(255, 243, 210);
    box-shadow: 0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.30);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3em;
    margin: 3em;
    flex-direction: column;
}

.cardNotFoundText {
    text-align: center;
    font-size: 1.8em;
    margin-top: 1vh;
    font-weight: 700;
    margin-left: 2vw;
    margin-right: 2vw;
    color: #ffaa00;
}

.cardNotFoundSubText {
    text-align: center;
    font-size: 1.1em;
    margin-top: 4vh;
    font-weight: 600;
    margin-left: 2vw;
    margin-right: 2vw;
    color: #ffaa00;
}

.cardNotFoundImage {
    object-fit: contain;
    height: 10vh;
    width: 70%;
    margin-top: 2vh;
}

.emptyPage {
    background: linear-gradient(270deg, rgba(244,244,244,1) 0%, rgba(244,244,244,1) 99%, rgba(208,208,208,1) 100%);
    box-shadow: 0.2rem 0.4rem 0.7rem rgba(0, 0, 0, 0.15);
}

.videoPageVideo {
    object-fit: contain;
    width: 100%;
}

.videoPageMusicCodeImage {
    object-fit: contain;
    width: 100%;
}

.musicCodeA {
    width: 100%;
}

.imagePageImage {
    object-fit: contain;
    height: 100vh;
    width: 100%;
}

.endText {
    text-align: center;
    font-size: 1.8em;
    margin-top: 1vh;
    font-weight: 700;
    margin-left: 2vw;
    margin-right: 2vw;
    color: #ffaa00;
}

.endSubText {
    text-align: center;
    font-size: 1.1em;
    margin-top: 4vh;
    font-weight: 600;
    margin-left: 2vw;
    margin-right: 2vw;
    color: #ffaa00;
}

.endImage {
    object-fit: contain;
    height: 10vh;
    width: 70%;
    margin-top: 8vh;
}

.replayImage {
    object-fit: contain;
    width: 20%;
    margin-top: 8vh;
}

.loading {
    background-color: rgb(255, 243, 210);
    box-shadow: 0.2rem 0.4rem 0.7rem rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100vw;
    flex-direction: column;
    position: absolute;
}

.loadingText {
    text-align: center;
    font-size: 1.1em;
    font-weight: 600;
    margin-left: 2vw;
    margin-right: 2vw;
    color: #ffaa00;
}

.loadingLoader {
    width: 15em;
}


